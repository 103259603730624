<template>
  <div>

    <b-row>

      <b-col>

        <fieldset v-if="platform" class="text-right list-fieldset">


          <div class="form-card text-left">
            <component
                v-bind:is="selectedPlatformComponent"
                :form="form"
                :lists="lists"
                @select="handleSelectList"
                ref="ListPlatformComponent"
            ></component>
          </div>

        </fieldset>

      </b-col>
    </b-row>

    <!-- Navigation buttons -->
    <NavigationLoWizard

        :isLoading = "isLoading"
        :valid = "valid"

        @cancel="$emit('cancel')"
        @next="next"
        @back="back"

    ></NavigationLoWizard>

  </div>


</template>

<script>

import { PLATFORMS_CODE } from "@/constantes";
import Multiselect from 'vue-multiselect'
import NavigationLoWizard from "@/components/inboxgeek/modals/Wizards/LoIntegration/NavigationLoWizard";

import convertkitLoListStep     from './list/ConvertKitLoListStep';
import CampaignerLoListStep     from './list/CampaignerLoListStep';
import KlaviyoLoListStep        from './list/KlaviyoLoListStep';
import MaropostLoListStep       from './list/MaropostLoListStep';
import omnisendLoListStep       from './list/OmniSendLoListStep';
import listrakLoListStep        from './list/ListrakLoListStep';
import gohighlevelLoListStep    from './list/GohighLevelLoListStep';
import ontraportLoListStep      from './list/OntraportLoListStep';
import sendlaneLoListStep       from './list/SendlaneLoListStep';
import sendgridLoListStep       from './list/SendGridLoListStep';
import mailsendLoListStep       from './list/MailSendLoListStep';
import getresponseLoListStep    from './list/GetResponseLoListStep';
import activecampaignLoListStep from './list/ActiveCampaignLoListStep';
import AweberLoListStep         from './list/AweberLoListStep';
import BlastableLoListStep      from './list/BlastableLoListStep';
import BrevoLoListStep          from './list/BrevoLoListStep';
import OngageLoListStep         from './list/OngageLoListStep';
import BeehiivLoListStep        from './list/BeehiivLoListStep';
import emailoctopusLoListStep   from './list/EmailOctopusLoListStep';
import InboxLoListStep          from './list/InboxLoListStep';
import HubspotLoListStep        from './list/HubspotLoListStep';
import MailchimpLoListStep      from './list/MailchimpLoListStep';
import keapLoListStep   from './list/keapLoListStep';
import expertsenderLoListStep   from './list/expertSenderLoListStep';
import WebhookLoListStep        from './list/WebhookLoListStep';

import UploadFileFiled from "@/components/inboxgeek/fileds/UploadFileFiled";
import BeehiivCustomFieldsInput from '@/components/inboxgeek/fileds/BeehiivCustomFieldsInput';
import ListStepOngage from '@/components/inboxgeek/modals/IntgrationModal/ListStepOngage.vue'
import ListStepMailchimp from '@/components/inboxgeek/modals/IntgrationModal/ListStepMailchimp.vue'
import ListStepBlastable from '@/components/inboxgeek/modals/IntgrationModal/ListStepBlastable.vue'
import ListStepOmnisend from '@/components/inboxgeek/modals/IntgrationModal/ListStepOmnisend.vue'


export default {
  name: "ListLoCreateIntegrationStep",
  props: ['account', 'form','platform', 'lists'],
  components: {
    Multiselect,
    NavigationLoWizard,
    UploadFileFiled,
    BeehiivCustomFieldsInput,
    ListStepOngage,
    ListStepMailchimp,
    ListStepBlastable,
    ListStepOmnisend,
    activecampaignLoListStep,
    AweberLoListStep,
    BlastableLoListStep,
    BrevoLoListStep,
    convertkitLoListStep,
    CampaignerLoListStep,
    KlaviyoLoListStep,
    MaropostLoListStep,
    omnisendLoListStep,
    listrakLoListStep,
    gohighlevelLoListStep,
    ontraportLoListStep,
    sendlaneLoListStep,
    sendgridLoListStep,
    mailsendLoListStep,
    getresponseLoListStep,
    OngageLoListStep,
    BeehiivLoListStep,
    emailoctopusLoListStep,
    InboxLoListStep,
    HubspotLoListStep,
    MailchimpLoListStep,
    keapLoListStep,
    expertsenderLoListStep,
    WebhookLoListStep
  },
  mounted() {},
  data() {
    return {
      isLoading: false,
      errors: {
        list: false
      },
      list: null,
      listLabel: 'List: *',
      listPlaceholder: 'Select Lists',
      listdisabled: false,
      platform_codes: PLATFORMS_CODE,

    }
  },
  methods: {

    next() {

      this.errors = {
        list: false
      };

      if (!this.list || !this.list.length) {
        this.errors.list = true;
        return;
      }

      this.$emit('next', { list: this.list });
    },
    back(){
      this.$emit('back', this.list);
    },
    uploadedList(lists) {
      if (lists[0]) {

        if (lists[0].code) {
          this.list = lists.map(list => { return { 'code': list.code, 'name': list.name }; });
        }

      }
    },
    listSelected(list) {

      if (list) {
        this.list = [{
          'code': list.code,
          'name': list.name,
          'type': list.type ?? 'list'
        }];
      } else {
        this.list = null;
      }
    },
    handleSelectList(itemSelected) {
      if (itemSelected) {

        this.list = itemSelected;
        this.$emit('preloadTags', { platform: this.platform, selectedLists: itemSelected })

      } else {
        this.list = null;
      }
    }
  },
  computed: {
    valid() {
      return !(!this.list || !this.list.length);

    },
    selectedPlatformComponent() {

      return  this.platform.name.toLowerCase() + 'LoListStep';
    }
  }

}

</script>

<style scoped>
.choice {
  cursor: pointer;
  border: 2px dashed #ff739a !important;
}

.choice:hover {
  box-shadow: 0px 0px 3px #f6386bdb, 1px 1px 13px #f6386b69 !important;
  border: 2px solid #ff739a !important;
}
</style>
